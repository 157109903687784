import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { User, checkGarconUrl, checkTicket, createFollowerHello, createIceCandidatesMessage, createLeaderHello, tryFetchSciezkaTicket } from "./Utils";
import { SciezkaTicketSubject } from "../client/TicketSubject";
import { Ticket } from "../generated/submodules/garcon-api/tickets_pb";
import { IcePolicy, RTCIceServerWithHostname } from "../client/Domain";
import Delay from "../client/Delay";
import CallSignaling from "../client/CallSignaling";
import LeaderSession from "../client/LeaderSession";
import FollowerSession from "../client/FollowerSession";
import { sciezka_messages } from "../generated/submodules/sciezka-messages/client_messages";
import { Subscription } from "rxjs";



export default class SampleCallCallerReconnect implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.Calls,
        Services.Garcon,
        Services.Kvashanina,
        Services.Sciezka,
    ]);
    readonly name: string = "Sample call with caller reconnect";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new SciezkaTicketsClient(garconUrl);

        const roomId = randomString();

        const callerUser = {
            accountId: randomString(),
            clientId: randomString(),
            roomId,
            subject: SciezkaTicketSubject.Caller,
        };

        const calleeUser = {
            accountId: randomString(),
            clientId: randomString(),
            roomId,
            subject: SciezkaTicketSubject.Callee,
        };

        const callerTicket = await tryFetchSciezkaTicket(client, callerUser);
        const calleeTicket = await tryFetchSciezkaTicket(client, calleeUser);

        [
            { ticket: callerTicket, user: callerUser },
            { ticket: calleeTicket, user: calleeUser },
        ].forEach(({ ticket, user }) => {
            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
            });
        });
        
        if (!callerTicket || !calleeTicket) {
            return;
        }

        await joinToCall(assert, {
            caller: {
                ticket: callerTicket,
                user: callerUser,
            },
            callee: {
                ticket: calleeTicket,
                user: calleeUser,
            },
        },
        async (user) => {
            const ticket = await tryFetchSciezkaTicket(client, user);
            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
            });
            return ticket;
        },
        roomId);
    }
}

async function joinToCall(
    assert: Assert,
    users: {
        caller: {
            ticket: Ticket,
            user: User & { subject: SciezkaTicketSubject },
        },
        callee: {
            ticket: Ticket,
            user: User & { subject: SciezkaTicketSubject },
        },
    },
    getTicket: (user: User & { subject: SciezkaTicketSubject }) => Promise<Ticket | null>,
    roomId: string,
): Promise<void> {
    const { caller, callee } = users;

    async function tryConnect(args: {
        callerSignalling: CallSignaling,
        calleeSignalling: CallSignaling,
        calleeSession: FollowerSession,
        callerSession?: LeaderSession,
        subscriptions: Array<Subscription>,
    }): Promise<{
        callerSignalling: CallSignaling,
        calleeSignalling: CallSignaling,
        calleeSession: FollowerSession,
        callerSession?: LeaderSession,
        subscriptions: Array<Subscription>,
    }> {
        const {
            calleeSignalling,
            calleeSession,
            callerSignalling,
            callerSession,
            subscriptions,
        } = args;

        const followerOffer = await calleeSession.createInitialOffer();

        assert.true(!!followerOffer, `Create and apply callee sdp offer accountId = ${callee.user.accountId}`);
        if (!followerOffer) {
            return args;
        }

        const followerHello = createFollowerHello(followerOffer, {
            mic: calleeSession.microphoneSendTransceiver.mid!,
            camera: calleeSession.cameraSendTransceiver.mid!,
            screenAudio: calleeSession.screenAudioSendTransceiver.mid!,
            screenVideo: calleeSession.screenVideoSendTransceiver.mid!,
        });

        calleeSignalling.send(new sciezka_messages.SciezkaMessage({
            follower_hello: followerHello,
        }));

        await Delay(1000);

        const { callerFollowerHello, calleeId } = (() => {
            const msg = callerMessages.pop();
            return { callerFollowerHello: msg?.message?.follower_hello, calleeId: msg?.source_client_id };
        })(); 
        assert.deepEqual(callerFollowerHello?.toObject(), followerHello.toObject(), `Callee send follower hello to caller`);
        assert.deepEqual(calleeId, callee.user.clientId, `Callee follower hello has expected clientId = ${calleeId}`);

        if (!callerFollowerHello || !calleeId) {
            return args;
        }

        const currentCallerSession = callerSession ?? new LeaderSession({
            followerHello: callerFollowerHello,
            callId: roomId,
            icePolicy: IcePolicy.All,
            iceServers: getIceServers(caller.ticket),
        });

        const result = {
            ...args,
            callerSession: currentCallerSession,
        }

        const calleeIceCandidates : Array<RTCIceCandidateInit> = [];
        subscriptions.push(calleeSession.iceCandidate.subscribe(x => calleeIceCandidates.push(x)));

        const callerIceCandidates : Array<RTCIceCandidateInit> = [];
        subscriptions.push(currentCallerSession.iceCandidate.subscribe(x => callerIceCandidates.push(x)));

        const applyOfferResult = await currentCallerSession.applyRemoteSdpOffer(callerFollowerHello.sdp_offer);
        assert.true(applyOfferResult, `Caller aplied remote sdp offer`);

        if (!applyOfferResult) {
            return result;
        }

        const leaderAnswer = await currentCallerSession.createAnswer();
        assert.true(!!leaderAnswer, `Create and apply caller sdp answer accountId = ${caller.user.accountId}`);
        if (!leaderAnswer) {
            return result;
        }

        const leaderHello = createLeaderHello(leaderAnswer);

        callerSignalling.send(new sciezka_messages.SciezkaMessage({
            leader_hello: leaderHello,
        }), calleeId);

        await Delay(1000);

        const calleeLeaderHello = calleeMessages.pop()?.message?.leader_hello;
        assert.deepEqual(calleeLeaderHello?.toObject(), leaderHello.toObject(), `Caller send leader hello to callee`);

        if (!calleeLeaderHello) {
            return result;
        }

        const applyAnswerResult = await calleeSession.applyRemoteSdpAnswer(calleeLeaderHello.sdp_answer);
        assert.true(applyAnswerResult, `Callee aplied remote sdp answer`);


        const calleeSelect = new sciezka_messages.Select();
        calleeSignalling.send(new sciezka_messages.SciezkaMessage({
            select: calleeSelect,
        }));

        await Delay(1000);

        const callerSelect = callerMessages.find(x => !!x?.message?.select);
        assert.deepEqual(callerSelect?.message?.select?.toObject(), calleeSelect.toObject(), `Callee send select to caller`);
        assert.deepEqual(callerSelect?.source_client_id, callee.user.clientId, `Callee send select to caller`);

        if (!callerSelect) {
            return result;
        }

        const callerEnableRtcReporting = callerMessages.find(x => !!x?.message?.enable_rtc_reporting);
        assert.deepEqual(callerEnableRtcReporting?.source_client_id, callee.user.clientId, `Caller received enable rtc reporting message`);

        const calleeEnableRtcReporting = calleeMessages.find(x => !!x?.message?.enable_rtc_reporting);
        assert.true(!!calleeEnableRtcReporting, `Callee received enable rtc reporting message`);

        if (!callerEnableRtcReporting || !calleeEnableRtcReporting) {
            return result;
        }

        cleanMessages(calleeMessages);
        cleanMessages(callerMessages);

        const calleeIceCandidatesMsg = createIceCandidatesMessage(calleeIceCandidates);
        const callerIceCandidatesMsg = createIceCandidatesMessage(callerIceCandidates);

        calleeSignalling.send(new sciezka_messages.SciezkaMessage({
            ice_candidates: calleeIceCandidatesMsg,
        }));

        callerSignalling.send(new sciezka_messages.SciezkaMessage({
            ice_candidates: callerIceCandidatesMsg,
        }), calleeId);

        await Delay(1000);

        const calleeIceCandidatesReceived = calleeMessages.pop()?.message?.ice_candidates;
        assert.deepEqual(calleeIceCandidatesReceived?.toObject(), callerIceCandidatesMsg.toObject(), `Caller send ice candidates to callee`);

        const { callerIceCandidatesReceived, callerIceCandidatesReceivedClientId } = (() => {
            const msg = callerMessages.pop();
            return { callerIceCandidatesReceived: msg?.message?.ice_candidates, callerIceCandidatesReceivedClientId: msg?.source_client_id };
        })(); 
        assert.deepEqual(callerIceCandidatesReceived?.toObject(), calleeIceCandidatesMsg.toObject(), `Callee send ice candidates to caller`);
        assert.deepEqual(callerIceCandidatesReceivedClientId, callee.user.clientId, `Caller receive ice candidates with correct clientId`);

        if (!calleeIceCandidatesReceived || !callerIceCandidatesReceived) {
            return result;
        }

        await Promise.all([
            ...calleeIceCandidatesReceived.ice_candidates.map(x => calleeSession.applyCandidate({
                candidate: x.candidate,
                sdpMid: x.sdp_mid,
                sdpMLineIndex: x.sdp_m_line_index,
                usernameFragment: x.username_fragment,
            })),
            ...callerIceCandidatesReceived.ice_candidates.map(x => currentCallerSession.applyCandidate({
                candidate: x.candidate,
                sdpMid: x.sdp_mid,
                sdpMLineIndex: x.sdp_m_line_index,
                usernameFragment: x.username_fragment,
            })),
        ]);

        await Delay(1000);

        assert.equal(calleeSession.connectionState, "connected", `Callee connected`);
        assert.equal(currentCallerSession.connectionState, "connected", `Caller connected`);

        return result;
    }

    async function connectSignalling(
        assert: Assert,
        endpoint: string,
        user: User,
        subject: SciezkaTicketSubject,
    ): Promise<CallSignaling | null> {
        const signalling = new CallSignaling();

        const result = await signalling.connect(endpoint);
        assert.true(result, `User ${user.accountId} connected to signalling server in room = ${user.roomId} with subject = ${subject}`);

        return result ? signalling : null;
    }

    const callerSignalling = await connectSignalling(
        assert,
        `${caller.ticket.getEndpoint()}?ticket=${caller.ticket.getTicket()}`,
        caller.user,
        caller.user.subject
    );

    const calleeSignalling = await connectSignalling(
        assert,
        `${callee.ticket.getEndpoint()}?ticket=${callee.ticket.getTicket()}`,
        callee.user,
        callee.user.subject
    );


    if (!callerSignalling || !calleeSignalling) {
        return;
    }

    function cleanMessages(messages: Array<sciezka_messages.OutgoingSciezkaMessage>) {
        while(messages.pop());
    }

    const subscriptions = [];

    const callerMessages : Array<sciezka_messages.OutgoingSciezkaMessage> = [];
    subscriptions.push(callerSignalling.messageReceived.subscribe(x => callerMessages.push(x)));
    
    const calleeMessages : Array<sciezka_messages.OutgoingSciezkaMessage> = [];
    subscriptions.push(calleeSignalling.messageReceived.subscribe(x => calleeMessages.push(x)));

    function getIceServers(ticket: Ticket): Array<RTCIceServerWithHostname> {
        return ticket.getIceServersList().flatMap(x => x.getIceServersList().map(y => {
            return {
                credential: y.hasCredential() ? y.getCredential() : undefined,
                username: y.hasUsername() ? y.getUsername() : undefined,
                urls: y.getUrlsList(),
                hostname: y.getHostname(),
            }
        }));
    }

    const calleeSession = new FollowerSession({
        callId: roomId,
        icePolicy: IcePolicy.All,
        iceServers: getIceServers(callee.ticket),
    });

    const firstObjects = await tryConnect({
        calleeSession,
        callerSignalling,
        calleeSignalling,
        subscriptions,
    });

    const callerTicket2 = await getTicket(caller.user);

    if (!callerTicket2) {
        return;
    }

    const callerSignalling2 = await connectSignalling(
        assert,
        `${callerTicket2.getEndpoint()}?ticket=${callerTicket2.getTicket()}`,
        caller.user,
        caller.user.subject
    );

    if (!callerSignalling2) {
        return;
    }

    subscriptions.push(callerSignalling2.messageReceived.subscribe(x => callerMessages.push(x)));

    await Delay(61000);

    assert.false(firstObjects.callerSignalling.isConnected(), `First caller session disconected`);

    const secondObjects = await tryConnect({
        ...firstObjects,
        callerSignalling: callerSignalling2
    });

    const callerDisconnectMsg = new sciezka_messages.Disconnect({
        reason: sciezka_messages.Disconnect.Reason.HANGUP,
    });
    secondObjects.callerSignalling.send(new sciezka_messages.SciezkaMessage({
        disconnect: callerDisconnectMsg,
    }));

    await Delay(5000);

    const calleeDisconnectReceived = calleeMessages.pop()?.message?.disconnect;

    assert.deepEqual(calleeDisconnectReceived?.toObject(), callerDisconnectMsg.toObject(), `Caller send disconnect to callee`);

    firstObjects.callerSignalling.close();
    secondObjects.callerSignalling.close();
    secondObjects.calleeSignalling.close();

    await secondObjects.calleeSession.terminate();
    await secondObjects.callerSession?.terminate();

    secondObjects.subscriptions.forEach(x => x.unsubscribe());
}

