import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { UserWithSubject, checkGarconUrl, checkTicket, connectSignalling, generateNUsers, tryFetchNTickets, mockVideoStream } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { Ticket } from "../generated/submodules/garcon-api/tickets_pb";
import { IcePolicy } from "../client/Domain";
import RoomSignaling from "../client/RoomSignaling";
import RoomSession from "../client/RoomSession";
import { AudioSource, TrackStatus } from "../client/Messages";
import { Subscription } from "rxjs";
import Delay from "../client/Delay";
import { RoomMembers } from "../client/RoomMembers";
import * as stat from '../client/WebRTCStatistics'
import { AwaitQueue } from "awaitqueue";



export default class SamplePttRoomWithMedia implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.AudioRoom,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);
    readonly name: string = "Sample audio room with media";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const { mediaStream } = await mockVideoStream();

        const client = new KaldunTicketsClient(garconUrl);

        const roomId = randomString();

        const talkUsers = generateNUsers(roomId, KaldunTicketSubject.AudioRoomTalk, 10);
        const listenUsers = generateNUsers(roomId, KaldunTicketSubject.AudioRoomListen, 50);

        const talkTickets = await tryFetchNTickets(client, { users: talkUsers });
        const listenTickets = await tryFetchNTickets(client, { users: listenUsers });

        [...talkTickets, ...listenTickets].forEach(({ ticket, user }) => {
            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
            });
        });
        
        const verifiedTalkTickets = talkTickets as Array<{ ticket: Ticket, user: UserWithSubject }>;
        const verifiedListenTickets = listenTickets as Array<{ ticket: Ticket, user: UserWithSubject }>;

        await joinToAudioRoom(assert, { talkTickets: verifiedTalkTickets, listenTickets: verifiedListenTickets }, roomId, mediaStream);
    }
}

async function joinToAudioRoom(
    assert: Assert,
    tickets: { talkTickets: Array<{ ticket: Ticket, user: UserWithSubject }>, listenTickets: Array<{ ticket: Ticket, user: UserWithSubject }> },
    roomId: string,
    stream: MediaStream,
): Promise<void> {
    async function connectSession(
        args: {
            signalling: RoomSignaling,
            user: UserWithSubject,
            ticket: Ticket,
        }
    ): Promise<{
        user: UserWithSubject,
        signalling: RoomSignaling,
        session: RoomSession,
        members: RoomMembers,
        subscription: Subscription,
    } | null> {
        const session = new RoomSession({
            roomId,
            enableScreenShare: false,
            icePolicy: IcePolicy.All,
            ticketSubject: args.user.subject,
            iceServers: args.ticket.getIceServersList().flatMap(x => x.getIceServersList().map(y => {
                return {
                    credential: y.hasCredential() ? y.getCredential() : undefined,
                    username: y.hasUsername() ? y.getUsername() : undefined,
                    urls: y.getUrlsList(),
                    hostname: y.getHostname(),
                }
            })),
        });

        const members = new RoomMembers();

        const queue = new AwaitQueue();

        const subscription = args.signalling.roomUpdatedNotification.subscribe(async event => {
            await queue.push(async () => {
                if (event.room_updated.consumed_media_sdp_offer) {
                    assert.true(await session.applyConsumedSdpOffer(event.room_updated.consumed_media_sdp_offer), `User ${args.user.accountId} aplied consumed sdp answer in room = ${roomId}`);
                }
    
                event.room_updated.members.forEach(member => members.updateMember(member));
    
                const unpauseList = members.unpauseCameraIncomingTracks();
                if (unpauseList.length > 0) {
                    const updateMediaResult = await args.signalling.updateMedia({
                        consumed_tracks: unpauseList,
                    });
    
                    assert.true(
                        "update_media" in updateMediaResult,
                        `User ${args.user.accountId} with subject ${args.user.subject} unpaused incoming tracks from room = ${roomId}`
                    );
                }
            });
        });

        return await queue.push(async () => {
            const offer = await session.createInitialOffer();
            const joinResult = await args.signalling.join({ offer });
        
            assert.true("join_room_v2" in joinResult, `User ${args.user.accountId} with subject = ${args.user.subject} joined to room = ${roomId}`);
        
            if ("join_room_v2" in joinResult) {
                assert.true(await session.applyProducedSdpOffer(joinResult.join_room_v2.sdp_offer), `User ${args.user.accountId} with subject = ${args.user.subject} aplied produced sdp offer in room = ${roomId}`);
                assert.true(await session.applyProducedSdpAnswer(joinResult.join_room_v2.sdp_answer), `User ${args.user.accountId} with subject = ${args.user.subject} aplied produced sdp answer in room = ${roomId}`);
                if (joinResult.join_room_v2.sdp_server_offer) {
                    assert.true(await session.applyConsumedSdpOffer(joinResult.join_room_v2.sdp_server_offer), `User ${args.user.accountId} with subject = ${args.user.subject} aplied consumed sdp answer in room = ${roomId}`);
                }
            } else {
                return null;
            };

            return {
                user: args.user,
                signalling: args.signalling,
                session,
                members,
                subscription,
            };
        });
    } 

    const sessions : Array<{
        user: UserWithSubject,
        signalling: RoomSignaling,
        session: RoomSession,
        members: RoomMembers,
        subscription: Subscription,
    }> = [];
    for(let { ticket, user } of [...tickets.listenTickets, ...tickets.talkTickets]) {
        const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, user.subject);
        if (!signalling) {
            return;
        }

        const sessionData = await connectSession({
            signalling,
            user,
            ticket,
        });

        if (!sessionData) {
            return;
        }

        sessions.push(sessionData);
    }

    for(let { user, session, signalling } of sessions.filter(x => x.user.subject === KaldunTicketSubject.AudioRoomTalk)) {
        session.microphoneSendTransceiver?.sender.replaceTrack(stream.getAudioTracks()[0]);

        const updateResult = await signalling.updateMedia({
            produced_tracks: [
                {
                    mid: session.microphoneSendTransceiver?.mid!,
                    src: { aud: AudioSource.Microphone },
                    st: TrackStatus.On,
                },
            ]
        });

        assert.true("update_media" in updateResult, `User ${user.accountId} with subject = ${user.subject} upated media from room = ${roomId}`);
    }

    await Delay(5000);

    for(let { session, user, members } of sessions) {
        const expected = members.viewState.flatMap(v => {
            const media : Array<{ accountId: string, mid: string, }> = [];
            if (v.cameraStream) {
                if (v.cameraStream.audioTrack && v.cameraStream.audioTrack.src) {
                    media.push({
                        accountId: v.id,
                        mid: v.cameraStream.audioTrack.mid,
                    });
                }
            }

            return media;
        });

        function sorter(
            a: { accountId: string, mid: string, },
            b: { accountId: string, mid: string, }
        ): number {

            if ([a.accountId, a.mid] < [b.accountId,b.mid]) {
                return -1;
            }
            if ([a.accountId, a.mid] > [b.accountId,b.mid]) {
                return 1;
            }

            return 0;
        }
        expected.sort(sorter);

        const actual : Array<{ accountId: string, mid: string, }> = []; 

        const report = await session.getStats();
        for (const subreport of report.values()) {
            const stats = subreport as stat.RTCStatsReport;
            if (stats.type === "inbound-rtp") {
                const inboundRtpStats = stats as stat.RTCInboundRtpStreamStats;
                if (!inboundRtpStats.mid) {
                    continue;
                }
                
                const memberAccountId = expected.find(x => x.mid === inboundRtpStats.mid)?.accountId;
                if (!memberAccountId) {
                    continue;
                }

                if (inboundRtpStats.kind === "audio" && inboundRtpStats.totalSamplesDecoded) {
                    actual.push({
                        accountId: memberAccountId,
                        mid: inboundRtpStats.mid,
                    });
                }   
            }
        }

        actual.sort(sorter);
        
        assert.deepEqual(actual, expected, `User ${user.accountId} with subject = ${user.subject} has incoming media from all members in room = ${roomId}`);
    }

    for(let session of sessions) {
        const leaveResult = await session.signalling.leave();
        assert.true("leave_room" in leaveResult, `User ${session.user.accountId} with subject = ${session.user.subject} leaved from room = ${roomId}`);
    }

    for(let session of sessions) {
        session.subscription.unsubscribe();
        await session.session.terminate();
    }
}