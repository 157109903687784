import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { User, checkGarconUrl, checkTicket, createFollowerHello, createIceCandidatesMessage, createLeaderHello, tryFetchSciezkaTicket } from "./Utils";
import { SciezkaTicketSubject } from "../client/TicketSubject";
import { Ticket } from "../generated/submodules/garcon-api/tickets_pb";
import { IcePolicy, RTCIceServerWithHostname } from "../client/Domain";
import Delay from "../client/Delay";
import CallSignaling from "../client/CallSignaling";
import LeaderSession from "../client/LeaderSession";
import FollowerSession from "../client/FollowerSession";
import { sciezka_messages } from "../generated/submodules/sciezka-messages/client_messages";
import { Subscription } from "rxjs";



export default class SampleCallWithTwoCalleeDevices implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.Calls,
        Services.Garcon,
        Services.Kvashanina,
        Services.Sciezka,
    ]);
    readonly name: string = "Sample call with two callee devices";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new SciezkaTicketsClient(garconUrl);

        const roomId = randomString();
        const calleeAccountId = randomString();

        const callerUser = {
            accountId: randomString(),
            clientId: randomString(),
            roomId,
            subject: SciezkaTicketSubject.Caller,
        };

        const calleeUser1 = {
            accountId: calleeAccountId,
            clientId: randomString(),
            roomId,
            subject: SciezkaTicketSubject.Callee,
        };

        const calleeUser2 = {
            accountId: calleeAccountId,
            clientId: randomString(),
            roomId,
            subject: SciezkaTicketSubject.Callee,
        };

        const callerTicket = await tryFetchSciezkaTicket(client, callerUser);
        const callee1Ticket = await tryFetchSciezkaTicket(client, calleeUser1);
        const callee2Ticket = await tryFetchSciezkaTicket(client, calleeUser2);

        [
            { ticket: callerTicket, user: callerUser },
            { ticket: callee1Ticket, user: calleeUser1 },
            { ticket: callee2Ticket, user: calleeUser2 },
        ].forEach(({ ticket, user }) => {
            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
            });
        });
        
        if (!callerTicket || !callee1Ticket || !callee2Ticket) {
            return;
        }

        await joinToCall(assert, {
            caller: {
                ticket: callerTicket,
                user: callerUser,
            },
            callee1: {
                ticket: callee1Ticket,
                user: calleeUser1,
            },
            callee2: {
                ticket: callee2Ticket,
                user: calleeUser2,
            },
        }, roomId);
    }
}

async function joinToCall(
    assert: Assert,
    users: {
        caller: {
            ticket: Ticket,
            user: User & { subject: SciezkaTicketSubject },
        },
        callee1: {
            ticket: Ticket,
            user: User & { subject: SciezkaTicketSubject },
        },
        callee2: {
            ticket: Ticket,
            user: User & { subject: SciezkaTicketSubject },
        },
    },
    roomId: string,
): Promise<void> {
    const { caller, callee1, callee2 } = users;

    async function connectSignalling(
        assert: Assert,
        endpoint: string,
        user: User,
        subject: SciezkaTicketSubject,
    ): Promise<CallSignaling | null> {
        const signalling = new CallSignaling();

        const result = await signalling.connect(endpoint);
        assert.true(result, `User ${user.accountId} connected to signalling server in room = ${user.roomId} with subject = ${subject}`);

        return result ? signalling : null;
    }

    const callerSignalling = await connectSignalling(
        assert,
        `${caller.ticket.getEndpoint()}?ticket=${caller.ticket.getTicket()}`,
        caller.user,
        caller.user.subject
    );

    const callee1Signalling = await connectSignalling(
        assert,
        `${callee1.ticket.getEndpoint()}?ticket=${callee1.ticket.getTicket()}`,
        callee1.user,
        callee1.user.subject
    );

    const callee2Signalling = await connectSignalling(
        assert,
        `${callee2.ticket.getEndpoint()}?ticket=${callee2.ticket.getTicket()}`,
        callee2.user,
        callee2.user.subject
    );


    if (!callerSignalling || !callee1Signalling || !callee2Signalling) {
        return;
    }

    function cleanMessages(messages: Array<sciezka_messages.OutgoingSciezkaMessage>) {
        while(messages.pop());
    }

    const subscriptions = [];

    const callerMessages : Array<sciezka_messages.OutgoingSciezkaMessage> = [];
    subscriptions.push(callerSignalling.messageReceived.subscribe(x => callerMessages.push(x)));
    
    const callee1Messages : Array<sciezka_messages.OutgoingSciezkaMessage> = [];
    subscriptions.push(callee1Signalling.messageReceived.subscribe(x => callee1Messages.push(x)));

    const callee2Messages : Array<sciezka_messages.OutgoingSciezkaMessage> = [];
    subscriptions.push(callee2Signalling.messageReceived.subscribe(x => callee2Messages.push(x)));

    function getIceServers(ticket: Ticket): Array<RTCIceServerWithHostname> {
        return ticket.getIceServersList().flatMap(x => x.getIceServersList().map(y => {
            return {
                credential: y.hasCredential() ? y.getCredential() : undefined,
                username: y.hasUsername() ? y.getUsername() : undefined,
                urls: y.getUrlsList(),
                hostname: y.getHostname(),
            }
        }));
    }

    async function tryNegotiate(args: {
        callee: User,
        calleeSignalling: CallSignaling,
        calleeMessages: Array<sciezka_messages.OutgoingSciezkaMessage>,
        callerSignalling: CallSignaling,
        callerMessages: Array<sciezka_messages.OutgoingSciezkaMessage>,
        subscriptions: Array<Subscription>,
    }): Promise<{
        calleeSession?: FollowerSession,
        callerSession?: LeaderSession,
        calleeIceCandidates: Array<RTCIceCandidateInit>,
        callerIceCandidates: Array<RTCIceCandidateInit>,
    }> {
        const {
            callee,
            calleeSignalling,
            calleeMessages,
            callerSignalling,
            callerMessages,
            subscriptions,
        } = args;

        const calleeSession = new FollowerSession({
            callId: roomId,
            icePolicy: IcePolicy.All,
            iceServers: getIceServers(callee1.ticket),
        });
    
        const calleeIceCandidates : Array<RTCIceCandidateInit> = [];
        subscriptions.push(calleeSession.iceCandidate.subscribe(x => calleeIceCandidates.push(x)));
    
        const followerOffer = await calleeSession.createInitialOffer();
    
        assert.true(!!followerOffer, `Create and apply callee sdp offer accountId = ${callee.accountId}, clientId = ${callee.clientId}`);
        if (!followerOffer) {
            return {
                calleeSession,
                calleeIceCandidates,
                callerIceCandidates: [],
            };
        }
    
        const followerHello = createFollowerHello(followerOffer, {
            mic: calleeSession.microphoneSendTransceiver.mid!,
            camera: calleeSession.cameraSendTransceiver.mid!,
            screenAudio: calleeSession.screenAudioSendTransceiver.mid!,
            screenVideo: calleeSession.screenVideoSendTransceiver.mid!,
        });
    
        calleeSignalling.send(new sciezka_messages.SciezkaMessage({
            follower_hello: followerHello,
        }));
    
        await Delay(1000);
    
        const { callerFollowerHello, calleeId } = (() => {
            const msg = callerMessages.pop();
            return { callerFollowerHello: msg?.message?.follower_hello, calleeId: msg?.source_client_id };
        })(); 
        assert.deepEqual(callerFollowerHello?.toObject(), followerHello.toObject(), `Callee with clientId = ${callee.clientId} send follower hello to caller`);
        assert.deepEqual(calleeId, callee.clientId, `Callee with clientId = ${callee.clientId} follower hello has expected clientId = ${calleeId}`);
    
        if (!callerFollowerHello || !calleeId) {
            return {
                calleeSession,
                calleeIceCandidates,
                callerIceCandidates: [],
            };
        }
    
        const callerSession = new LeaderSession({
            followerHello: callerFollowerHello,
            callId: roomId,
            icePolicy: IcePolicy.All,
            iceServers: getIceServers(caller.ticket),
        })
    
        const callerIceCandidates : Array<RTCIceCandidateInit> = [];
        subscriptions.push(callerSession.iceCandidate.subscribe(x => callerIceCandidates.push(x)));
    
        const applyOfferResult = await callerSession.applyRemoteSdpOffer(callerFollowerHello.sdp_offer);
        assert.true(applyOfferResult, `Caller aplied remote sdp offer`);
    
        if (!applyOfferResult) {
            return {
                calleeSession,
                callerSession,
                calleeIceCandidates,
                callerIceCandidates,
            };
        }
    
        const leaderAnswer = await callerSession.createAnswer();
        assert.true(!!leaderAnswer, `Create and apply caller sdp answer accountId = ${caller.user.accountId}`);
        if (!leaderAnswer) {
            return {
                calleeSession,
                callerSession,
                calleeIceCandidates,
                callerIceCandidates,
            };
        }
    
        const leaderHello = createLeaderHello(leaderAnswer);
    
        callerSignalling.send(new sciezka_messages.SciezkaMessage({
            leader_hello: leaderHello,
        }), calleeId);
    
        await Delay(1000);
    
        const calleeLeaderHello = calleeMessages.pop()?.message?.leader_hello;
        assert.deepEqual(calleeLeaderHello?.toObject(), leaderHello.toObject(), `Caller send leader hello to callee with clientId = ${callee.clientId}`);
    
        if (!calleeLeaderHello) {
            return {
                calleeSession,
                callerSession,
                calleeIceCandidates,
                callerIceCandidates,
            };
        }
    
        const applyAnswerResult = await calleeSession.applyRemoteSdpAnswer(calleeLeaderHello.sdp_answer);
        assert.true(applyAnswerResult, `Callee with clientId = ${callee.clientId} aplied remote sdp answer`);

        return {
            calleeSession,
            callerSession,
            calleeIceCandidates,
            callerIceCandidates,
        };
    }

    const sessions1 = await tryNegotiate({
        callee: callee1.user,
        calleeMessages: callee1Messages,
        calleeSignalling: callee1Signalling,
        callerMessages,
        callerSignalling,
        subscriptions,
    });

    const sessions2 = await tryNegotiate({
        callee: callee2.user,
        calleeMessages: callee2Messages,
        calleeSignalling: callee2Signalling,
        callerMessages,
        callerSignalling,
        subscriptions,
    });

    const callee2Select = new sciezka_messages.Select();
    callee2Signalling.send(new sciezka_messages.SciezkaMessage({
        select: callee2Select,
    }));

    await Delay(1000);

    const callerSelect = callerMessages.find(x => !!x?.message?.select);
    assert.deepEqual(callerSelect?.message?.select?.toObject(), callee2Select.toObject(), `Callee with clientId = ${callee2.user.clientId} second device send select to caller`);
    assert.deepEqual(callerSelect?.source_client_id, callee2.user.clientId, `Select sourceId is correct`);

    if (!callerSelect) {
        return;
    }


    const callerDisconnectMsg = new sciezka_messages.Disconnect({
        reason: sciezka_messages.Disconnect.Reason.CONNECTED_ON_OTHER_DEVICE,
    });
    callerSignalling.send(new sciezka_messages.SciezkaMessage({
        disconnect: callerDisconnectMsg,
    }));

    await Delay(1000);

    const callee1DisconnectReceived = callee1Messages.pop()?.message?.disconnect;
    assert.deepEqual(callee1DisconnectReceived?.toObject(), callerDisconnectMsg.toObject(), `Caller send disconnect to callee with clientId = ${callee1.user.clientId}`);

    const callerEnableRtcReporting = callerMessages.find(x => !!x?.message?.enable_rtc_reporting);
    assert.deepEqual(callerEnableRtcReporting?.source_client_id, callee2.user.clientId, `Caller received enable rtc reporting message`);

    const calleeEnableRtcReporting = callee2Messages.find(x => !!x?.message?.enable_rtc_reporting);
    assert.true(!!calleeEnableRtcReporting, `Callee received enable rtc reporting message`);

    if (!callerEnableRtcReporting || !calleeEnableRtcReporting) {
        return;
    }

    cleanMessages(callee2Messages);
    cleanMessages(callerMessages);

    const calleeIceCandidatesMsg = createIceCandidatesMessage(sessions2.calleeIceCandidates);
    const callerIceCandidatesMsg = createIceCandidatesMessage(sessions2.callerIceCandidates);

    callee2Signalling.send(new sciezka_messages.SciezkaMessage({
        ice_candidates: calleeIceCandidatesMsg,
    }));

    callerSignalling.send(new sciezka_messages.SciezkaMessage({
        ice_candidates: callerIceCandidatesMsg,
    }), callee2.user.clientId);

    await Delay(1000);

    const calleeIceCandidatesReceived = callee2Messages.pop()?.message?.ice_candidates;
    assert.deepEqual(calleeIceCandidatesReceived?.toObject(), callerIceCandidatesMsg.toObject(), `Caller send ice candidates to callee with accountId = ${callee2.user.accountId}, clientId = ${callee2.user.clientId}`);

    const { callerIceCandidatesReceived, callerIceCandidatesReceivedClientId } = (() => {
        const msg = callerMessages.pop();
        return { callerIceCandidatesReceived: msg?.message?.ice_candidates, callerIceCandidatesReceivedClientId: msg?.source_client_id };
    })(); 
    assert.deepEqual(callerIceCandidatesReceived?.toObject(), calleeIceCandidatesMsg.toObject(), `Callee accountId = ${callee2.user.accountId}, clientId = ${callee2.user.clientId} send ice candidates to caller`);
    assert.deepEqual(callerIceCandidatesReceivedClientId, callee2.user.clientId, `Caller receive ice candidates with correct clientId = ${callee2.user.clientId}`);

    if (!calleeIceCandidatesReceived || !callerIceCandidatesReceived) {
        return;
    }

    await Promise.all([
        ...calleeIceCandidatesReceived.ice_candidates.map(x => sessions2.calleeSession?.applyCandidate({
            candidate: x.candidate,
            sdpMid: x.sdp_mid,
            sdpMLineIndex: x.sdp_m_line_index,
            usernameFragment: x.username_fragment,
        })),
        ...callerIceCandidatesReceived.ice_candidates.map(x => sessions2.callerSession?.applyCandidate({
            candidate: x.candidate,
            sdpMid: x.sdp_mid,
            sdpMLineIndex: x.sdp_m_line_index,
            usernameFragment: x.username_fragment,
        })),
    ]);

    await Delay(5000);

    assert.equal(sessions2?.calleeSession?.connectionState, "connected", `Callee connected accountId = ${callee2.user.accountId}, clientId = ${callee2.user.clientId}`);
    assert.equal(sessions2?.callerSession?.connectionState, "connected", `Caller connected`);

    const calleeDisconnectMsg = new sciezka_messages.Disconnect({
        reason: sciezka_messages.Disconnect.Reason.HANGUP,
    });
    callee2Signalling.send(new sciezka_messages.SciezkaMessage({
        disconnect: calleeDisconnectMsg,
    }));
    callee2Signalling.close();

    await Delay(5000);

    const { callerDisconnectReceived, callerDisconnectReceivedClientId } = (() => {
        const msg = callerMessages.pop();
        return { callerDisconnectReceived: msg?.message?.disconnect, callerDisconnectReceivedClientId: msg?.source_client_id };
    })(); 
    assert.deepEqual(callerDisconnectReceived?.toObject(), calleeDisconnectMsg.toObject(), `Callee with clientId = ${callee2.user.clientId} send disconnect to caller`);
    assert.deepEqual(callerDisconnectReceivedClientId, callee2.user.clientId, `Caller receive disconnect with correct clientId = ${callee2.user.clientId}`);

    callerSignalling.close();
    callee1Signalling.close();
    callee2Signalling.close();

    await sessions1?.calleeSession?.terminate();
    await sessions1?.callerSession?.terminate();

    await sessions2?.calleeSession?.terminate();
    await sessions2?.callerSession?.terminate();

    subscriptions.forEach(x => x.unsubscribe());
}
