import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { UserWithSubject, checkGarconUrl, checkTicket, connectSignalling, generateNUsers, tryFetchNTickets } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { Ticket } from "../generated/submodules/garcon-api/tickets_pb";
import { IcePolicy } from "../client/Domain";
import RoomSignaling from "../client/RoomSignaling";
import RoomSession from "../client/RoomSession";
import { MemberInfo, MemberStatus } from "../client/Messages";
import { Subscription } from "rxjs";
import Delay from "../client/Delay";
import { AwaitQueue } from "awaitqueue";



export default class SampleAudioRoomLimitsCheck implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.AudioRoom,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);
    readonly name: string = "Sample audio room with limits check";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const roomId = randomString();

        const talkUsers = generateNUsers(roomId, KaldunTicketSubject.AudioRoomTalk, 15);
        const afterLimitTalkUsers = generateNUsers(roomId, KaldunTicketSubject.AudioRoomTalk, 3);
        const listenUsers = generateNUsers(roomId, KaldunTicketSubject.AudioRoomListen, 20);

        const talkTickets = await tryFetchNTickets(client, { users: talkUsers });
        const afterLimitTalkTickets = await tryFetchNTickets(client, { users: afterLimitTalkUsers });
        const listenTickets = await tryFetchNTickets(client, { users: listenUsers });

        [...talkTickets, ...afterLimitTalkTickets, ...listenTickets].forEach(({ ticket, user }) => {
            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
            });
        });
        
        const verifiedTalkTickets = talkTickets as Array<{ ticket: Ticket, user: UserWithSubject }>;
        const verifiedAfterLimitTalkTickets = afterLimitTalkTickets as Array<{ ticket: Ticket, user: UserWithSubject }>;
        const verifiedListenTickets = listenTickets as Array<{ ticket: Ticket, user: UserWithSubject }>;

        await joinToAudioRoom(assert, {
            talkTickets: verifiedTalkTickets,
            afterLimitTalkTickets: verifiedAfterLimitTalkTickets,
            listenTickets: verifiedListenTickets,
        }, roomId);
    }
}

async function joinToAudioRoom(
    assert: Assert,
    tickets: {
        talkTickets: Array<{ ticket: Ticket, user: UserWithSubject }>,
        afterLimitTalkTickets: Array<{ ticket: Ticket, user: UserWithSubject }>,
        listenTickets: Array<{ ticket: Ticket, user: UserWithSubject }>, },
    roomId: string,
): Promise<void> {
    async function connectSession(
        args: {
            signalling: RoomSignaling,
            user: UserWithSubject,
            ticket: Ticket,
            afterLimit: boolean,
        }
    ): Promise<{
        user: UserWithSubject,
        signalling: RoomSignaling,
        session: RoomSession,
        members: Array<MemberInfo>,
        subscription: Subscription,
    } | null> {
        const session = new RoomSession({
            roomId,
            icePolicy: IcePolicy.All,
            enableScreenShare: false,
            ticketSubject: args.user.subject,
            iceServers: args.ticket.getIceServersList().flatMap(x => x.getIceServersList().map(y => {
                return {
                    credential: y.hasCredential() ? y.getCredential() : undefined,
                    username: y.hasUsername() ? y.getUsername() : undefined,
                    urls: y.getUrlsList(),
                    hostname: y.getHostname(),
                }
            })),
        });

        const members : Array<MemberInfo> = [];

        const queue = new AwaitQueue();

        const subscription = args.signalling.roomUpdatedNotification.subscribe(async event => {
            await queue.push(async () => {
                if (event.room_updated.consumed_media_sdp_offer) {
                    assert.true(await session.applyConsumedSdpOffer(event.room_updated.consumed_media_sdp_offer), `User ${args.user.accountId} aplied consumed sdp answer in room = ${roomId}, subject = ${args.user.subject}`);
                }
                members.push(...event.room_updated.members.filter(member => member.st === MemberStatus.Connected));
            });
        });

        return queue.push(async () => {
            const offer = await session.createInitialOffer();
            const joinResult = await args.signalling.join({ offer });
        
            if (args.afterLimit) {
                assert.true("error" in joinResult, `User ${args.user.accountId} cannot join with subject = ${args.user.subject} to room = ${roomId} because limit is reached`);
                return null;
            } else {
                assert.true("join_room_v2" in joinResult, `User ${args.user.accountId} joined to room = ${roomId}`);
        
                if ("join_room_v2" in joinResult) {
                    assert.true(await session.applyProducedSdpOffer(joinResult.join_room_v2.sdp_offer), `User ${args.user.accountId} aplied produced sdp offer in room = ${roomId}, subject = ${args.user.subject}`);
                    assert.true(await session.applyProducedSdpAnswer(joinResult.join_room_v2.sdp_answer), `User ${args.user.accountId} aplied produced sdp answer in room = ${roomId}, subject = ${args.user.subject}`);
                    if (joinResult.join_room_v2.sdp_server_offer) {
                        assert.true(await session.applyConsumedSdpOffer(joinResult.join_room_v2.sdp_server_offer), `User ${args.user.accountId} aplied consumed sdp answer in room = ${roomId}, subject = ${args.user.subject}`);
                    }
                } else {
                    return null;
                };

                return {
                    user: args.user,
                    signalling: args.signalling,
                    session,
                    members,
                    subscription,
                };
            }
        });
    } 

    const sessions : Array<{
        user: UserWithSubject,
        signalling: RoomSignaling,
        session: RoomSession,
        members: Array<MemberInfo>,
        subscription: Subscription,
    }> = [];
    for(let { ticket, user } of [ ...tickets.listenTickets, ...tickets.talkTickets]) {
        const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, user.subject);
        if (!signalling) {
            return;
        }

        const sessionData = await connectSession({
            signalling,
            user,
            ticket,
            afterLimit: false,
        });

        if (!sessionData) {
            return;
        }

        sessions.push(sessionData);
    }

    for(let { ticket, user } of tickets.afterLimitTalkTickets) {
        const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, user.subject);
        if (!signalling) {
            return;
        }

        await connectSession({
            signalling,
            user,
            ticket,
            afterLimit: true,
        });
    }

    await Delay(5000);

    for(let session of sessions) {
        const leaveResult = await session.signalling.leave();
        assert.true("leave_room" in leaveResult, `User ${session.user.accountId} leaved from room = ${roomId}, subject = ${session.user.subject}`);
    }

    for(let session of sessions) {
        session.subscription.unsubscribe();
        await session.session.terminate();
    }
}